import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexaToRgba',
  standalone: true,
})
export class HexaToRgbaPipe implements PipeTransform {
  transform(value: string, alpha: string): string {
    if (!value) {
      return null;
    }
    const r = parseInt(value.slice(1, 3), 16);
    const g = parseInt(value.slice(3, 5), 16);
    const b = parseInt(value.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
}
